var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticStyle:{"height":"100vh"},attrs:{"id":"sidebar"}},[_c('div',{staticClass:"side_container",staticStyle:{"width":"250px"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/common/logo01.png"),"alt":""}}),_c('p',[_vm._v("案件")]),(_vm.profile)?_c('div',{staticClass:"wrap"},[(!_vm.profile.type)?_c('ul',{staticClass:"first"},[_c('li',{class:_vm.$route.path == '/request' ? 'current_page' : ''},[_vm._v(" 依頼一覧 "),_c('router-link',{attrs:{"to":"/request"}})],1),_c('li',{class:_vm.$route.path == '/request/mysuggestion'
                            ? 'current_page'
                            : ''},[_vm._v(" 提案中の依頼一覧"),_c('router-link',{attrs:{"to":"/request/mysuggestion"}})],1),_c('li',{class:_vm.$route.path == '/task' ? 'current_page' : ''},[_vm._v(" タスク"),_c('router-link',{attrs:{"to":"/task"}})],1),_c('li',{class:_vm.$route.path == '/company' ? 'current_page' : ''},[_vm._v(" 対応中企業一覧"),_c('router-link',{attrs:{"to":"/company"}})],1),_c('li',{class:_vm.$route.path == '/review' ? 'current_page' : ''},[_vm._v(" レビュー "),_c('router-link',{attrs:{"to":"/review"}})],1),_c('li',{class:_vm.$route.path == '/notice' ? 'current_page' : ''},[_vm._v(" 通知 "),_c('router-link',{attrs:{"to":"/notice"}}),(_vm.unread != 0)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.unread,"type":"primary"}}):_vm._e()],1),_c('li',{class:_vm.$route.path == '/substitute' ? 'current_page' : ''},[_vm._v(" 代理登録 "),_c('router-link',{attrs:{"to":"/substitute"}})],1)]):_c('ul',{staticClass:"first"},[_c('li',{class:_vm.$route.path == '/request' ? 'current_page' : ''},[_vm._v(" 依頼一覧 "),_c('router-link',{attrs:{"to":"/request"}})],1),_c('li',{class:_vm.$route.path == '/request/create'
                            ? 'current_page'
                            : ''},[_vm._v(" 依頼新規作成 "),_c('router-link',{attrs:{"to":"/request/create"}})],1),_c('li',{class:_vm.$route.path == '/task' ? 'current_page' : ''},[_vm._v(" タスク"),_c('router-link',{attrs:{"to":"/task"}})],1),_c('li',{class:_vm.$route.path == '/counsellor' ? 'current_page' : ''},[_vm._v(" 依頼中税理士の一覧"),_c('router-link',{attrs:{"to":"/counsellor"}})],1),_c('li',{class:_vm.$route.path == '/drive' ? 'current_page' : ''},[_vm._v(" ファイル一覧 "),_c('router-link',{attrs:{"to":"/drive"}})],1),_c('li',{class:_vm.$route.path == '/account' ? 'current_page' : ''},[_vm._v(" 共有リンク "),_c('router-link',{attrs:{"to":"/account"}})],1),_c('li',{class:_vm.$route.path == '/authorise' ? 'current_page' : ''},[_vm._v(" ファイル閲覧権限設定 "),_c('router-link',{attrs:{"to":"/authorise"}})],1),_c('li',{class:_vm.$route.path == '/notice' ? 'current_page' : ''},[_vm._v(" 通知 "),_c('router-link',{attrs:{"to":"/notice"}}),(_vm.unread != 0)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.unread,"type":"primary"}}):_vm._e()],1)]),_c('ul',{staticClass:"second"},[_c('li',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.logout}},[_vm._v("ログアウト")]),_c('li',{class:_vm.$route.path == '/contact' ? 'current_page' : ''},[_vm._v(" お問い合わせ"),_c('router-link',{attrs:{"to":"/contact"}})],1),(_vm.profile)?[(_vm.profile.type == 1)?_c('li',{class:_vm.$route.path == '/company/' + _vm.profile.id
                                ? 'current_page'
                                : ''},[_c('router-link',{attrs:{"to":'/company/' + _vm.profile.id}}),_vm._v(" 会員情報 ")],1):_c('li',{class:_vm.$route.path == '/counsellor/' + _vm.profile.id
                                ? 'current_page'
                                : ''},[_c('router-link',{attrs:{"to":'/counsellor/' + _vm.profile.id}}),_vm._v(" 会員情報 ")],1)]:_vm._e(),_c('li',{class:_vm.$route.path == '/mypage/edit' ? 'current_page' : ''},[_c('router-link',{attrs:{"to":"/mypage/edit"}}),_vm._v(" 会員情報の編集 ")],1)],2)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }