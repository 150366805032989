var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"unlogin"}},[_c('header',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/common/logo_pc.png"),"alt":""}})]),_c('div',{staticClass:"right_wrap pc"},[_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" ログイン ")]),_c('router-link',{attrs:{"to":{
                    name: 'home',
                    hash: '#flow',
                }}},[_vm._v(" 利用方法 ")]),_c('router-link',{attrs:{"to":"/guest/contact"}},[_vm._v(" お問合せ ")]),_c('router-link',{staticClass:"button",attrs:{"to":"/type"}},[_vm._v(" 会員登録 ")])],1)],1),_c('ul',{staticClass:"humb_btn",on:{"click":function($event){_vm.flag ? (_vm.flag = false) : (_vm.flag = true)}}},[(!_vm.flag)?_c('li',[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v(" menu ")])]):_c('li',[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v(" close ")])])]),(_vm.flag)?_c('div',{staticClass:"hamburger",style:(_vm.flag ? 'right:0;' : '')},[_c('ul',[_c('li',[_vm._v(" ログイン "),_c('router-link',{attrs:{"to":"/login"},nativeOn:{"click":function($event){_vm.flag = false}}})],1),_c('li',[_vm._v(" 利用方法 "),_c('router-link',{attrs:{"to":{
                        name: 'home',
                        hash: '#flow',
                    }},nativeOn:{"click":function($event){_vm.flag = false}}})],1),_c('li',[_vm._v(" お問合せ "),_c('router-link',{attrs:{"to":"/guest/contact"},nativeOn:{"click":function($event){_vm.flag = false}}})],1),_c('li',[_vm._v(" 会員登録 "),_c('router-link',{staticClass:"button",attrs:{"to":"/type"},nativeOn:{"click":function($event){_vm.flag = false}}})],1)])]):_vm._e(),_c('main',{class:_vm.$route.path != '/' ? 'main_layout' : ''},[_c('router-view')],1),_c('footer',{attrs:{"id":"footer"}},[_c('div',{staticClass:"footer_container"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('ul',[_c('li',[_vm._v("事業者の方へ")]),_c('li',[_vm._v(" ログイン "),_c('router-link',{attrs:{"to":"/login"},on:{"click":function($event){_vm.flag = false}}})],1),_c('li',[_vm._v(" 会員登録 "),_c('router-link',{attrs:{"to":"/sendmail/company"}})],1)]),_c('ul',[_c('li',[_vm._v("税理士の方へ")]),_c('li',[_vm._v(" ログイン "),_c('router-link',{attrs:{"to":"/login"}})],1),_c('li',[_vm._v(" 会員登録 "),_c('router-link',{attrs:{"to":"/sendmail/counsellor"}})],1)]),_c('ul',[_c('li',[_vm._v("本サービスについて")]),_c('li',[_vm._v(" ご利用方法 "),_c('router-link',{attrs:{"to":{
                                name: 'home',
                                hash: '#flow',
                            }}})],1),_vm._m(1),_c('li',[_vm._v(" プライバシーポリシー "),_c('router-link',{attrs:{"to":"/privacy-policy"}})],1)])])]),_c('p',[_vm._v("Copyright 2022 - a-Tax All right Recived")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/img/common/logo-w.png"),"alt":""}}),_c('p',[_vm._v(" 運営会社：株式会社アカウンタックス"),_c('br'),_vm._v(" 住所：東京都千代田区平河町２−１１−２ユースクエア平河町２階 "),_c('br'),_vm._v(" Tel："),_c('a',{attrs:{"href":"tel:03-3237-1311"}},[_vm._v("03-3237-1311")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v(" 運営会社 "),_c('a',{attrs:{"href":"https://accountax.co.jp","target":"_blank"}})])
}]

export { render, staticRenderFns }